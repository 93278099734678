'use strict';

export default {
	Api: {
		post: function (blog_id, blog_post_id) {
			return `/my/api/2/blog/${blog_id}/post/${blog_post_id}`;
		},
		posts: function (blog_id) {
			return `/my/api/2/blog/${blog_id}/search`;
		}
	},
	Templates: {
		search: '/my/api/2/templates?name=modules/blog-roll/search.html'
	}
};
