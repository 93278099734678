import BlogSearchView from './views/search.js';
import SliderView from './views/slider.js';
import VideosView from './views/videos.js';

export default class BlogRoll {
	constructor({ el, blog_id, blog_url, blog_post_id, scroll_load, slider, videos }) {
		const settings = window.BlogSettings ? window.BlogSettings : {};

		this.views = {
			search: new BlogSearchView({
				el,
				blog_id,
				blog_url,
				blog_post_id,
				scroll_load,
				settings
			}),
			slider: slider ? new SliderView() : null,
			videos: videos ? new VideosView() : null
		};
	}
}
