import Session from '../../modules/session/js/app.js';
import CustomerMenu from '../../modules/customer-menu/js/app.js';
import BlogRoll from '../../modules/blog-roll/js/app.js';
import checkMapPricing from '../utils/check-map-pricing.js';

class BlogPage {
	constructor() {
		this.settings = window.BlogSettings || {};
		this.BodyElement = document.querySelector('body');
		this.SideMenuBtnElement = document.querySelector('.js-toggle-side-menu');
		this.SideMenuBtnIconElement = this.SideMenuBtnElement.querySelector('.js-menu-icon');
		this.SideMenuBtnProgressIconElement = this.SideMenuBtnElement.querySelector('.js-menu-progress-icon');
		this.SideMenuCloseBtnElements = document.querySelectorAll('.js-close-side-menu-btn');
		this.CategoryListPlaceHolderElement = document.querySelector('.js-blog-categories-list-placeholder');

		this.init();
	}

	init() {
		this.bindEvents();
		this.initRoll();
	}

	getCategories() {
		const { BlogId } = this.settings;
		const url = `/my/api/2/blog/${BlogId}/categories`;
		const xhr = new XMLHttpRequest();

		xhr.responseType = 'json';
		xhr.open('GET', url);
		xhr.send();

		this.SideMenuBtnIconElement.classList.add('d-none');
		this.SideMenuBtnProgressIconElement.classList.remove('d-none');

		xhr.onload = () => {
			if (xhr.readyState === 4 && xhr.status === 200) {
				this.renderCategories(xhr.response);
			}
		};
	}

	renderCategories(categories) {
		const categoriesElements = this.compileCategories(categories);

		categoriesElements.forEach(item => {
			this.CategoryListPlaceHolderElement.appendChild(item);
		});

		this.SideMenuBtnIconElement.classList.remove('d-none');
		this.SideMenuBtnProgressIconElement.classList.add('d-none');

		this.SideMenuBtnElement.setAttribute('data-menu-state', true);

		this.BodyElement.classList.toggle('menu-open');
	}

	compileCategories(list) {
		return list.map(category => {
			const li = document.createElement(`li`);
			const link = document.createElement(`a`);
			const badge = document.createElement(`span`);

			badge.setAttribute('class', 'badge');
			badge.innerText = category.CountPosts;

			link.setAttribute('href', category.Url);
			link.innerText = category.Name;
			link.appendChild(badge);

			li.appendChild(link);

			return li;
		});
	}

	bindEvents() {
		this.SideMenuBtnElement.addEventListener('click', event => {
			event.preventDefault();
			const hasCategories = this.SideMenuBtnElement.getAttribute('data-menu-state') === 'true';

			if (hasCategories) {
				this.BodyElement.classList.toggle('menu-open');
			} else {
				this.getCategories();
			}
		});

		if (this.SideMenuCloseBtnElements && this.SideMenuCloseBtnElements.length) {
			this.SideMenuCloseBtnElements.forEach(element => {
				element.addEventListener('click', event => {
					event.preventDefault();

					this.BodyElement.classList.remove('menu-open');
				});
			});
		}
	}

	initRoll() {
		const { BlogId, BlogURL, BlogPostID } = this.settings;

		new BlogRoll({
			el: '.blog-search-app',
			blog_id: BlogId,
			blog_url: BlogURL,
			blog_post_id: BlogPostID,
			scroll_load: false,
			slider: false,
			videos: false,
			likely: false
		});
	}
}

window.Session = new Session();

if (!window.BlogSettings.ShowStoreMenu) {
	window.Session.get('Customer')
		.then(function (customer) {
			new CustomerMenu({
				el: '.app-customer-menu',
				customer: customer
			});
		});
}

if (window.BlogSettings.PRODUCT_PRICE_POLICY && window.BlogSettings.PRODUCT_PRICE_POLICY === 'MAP') {
	Promise.all([window.Session.get('Customer'), window.Session.get('ContactInfo')])
		.then(function(results) {
			checkMapPricing(results[0], results[1]);
		});
}

new BlogPage();
