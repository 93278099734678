const selectors = {
	map_pricing: {
		show: '.js-map-show',
		hide: '.js-map-hide',
		remove: '.js-map-remove',
		approved: 'js-map-approved'
	}
};

const canProcess = function (elem, customer) {
	if (elem.classList.contains(selectors.map_pricing.approved) && !customer.is_approved) {
		return false;
	} else {
		return true;
	}
};

const getCustomerData = function (customerData, contactData) {
	let customer = customerData;

	if (!customerData || customerData.guest && contactData) {
		customer = contactData;
	}
	return customer;
};

const checkMapPricing = function (customerData, contactData) {
	const customer = getCustomerData(customerData, contactData);

	if (customer && !customer.guest) {
		document.querySelectorAll(selectors.map_pricing.show).forEach((elem) => {
			if (canProcess(elem, customer)) {
				elem.classList.remove('hide');
			}
		});
		document.querySelectorAll(selectors.map_pricing.hide).forEach((elem) => {
			if (canProcess(elem, customer)) {
				elem.classList.add('hide')
			}
		});
		document.querySelectorAll(selectors.map_pricing.remove).forEach((elem) => {
			if (canProcess(elem, customer)) {
				elem.remove()
			}
		});
	}
};

export default checkMapPricing;