import Urls from '../urls.js';
import { fetcher } from '../../../../scripts/utils/fetch.js';
import BaseView from '../../../../scripts/utils/base-view.js';

export default class BlogSearchView extends BaseView {
	constructor({ el, blog_id, blog_url, blog_post_id, scroll_load, settings }) {
		super(el);
		this.template_url = Urls.Templates.search;
		this.blog_id = blog_id;
		this.blog_url = blog_url;
		this.blog_post_id = blog_post_id;
		this.scroll_load = scroll_load;
		this.settings = settings;

		this.selectors = {
			posts: '.blog-content-body',
			post: '.blog-card',
			paginator: '.paginator',
			searchForm: 'form.search',
			searchField: '.search [name="query"]',
			btnSearch: '.search .btn-search',
			btnReset: '.search .btn-reset',
			btnMore: '.load-more'
		};

		this.page = 1;
		this.posts_per_page = this.settings.BLOG_POSTS_PER_PAGE || 15;
		this.all_loaded = false;
		this.paginator = null;
		this.posts = null;

		this.bindSelectors();
		this.bindFormEvents();

		if (this.scroll_load) {
			this.initScrollLoad();
		}

		if(this.blog_post_id) {
			this.visitPost(this.blog_id, this.blog_post_id);
		}

		this.checkLocation();
		this.checkCount();
	}

	get_context() {
		const { posts, paginator } = this;
		return {
			posts,
			paginator,
			query: this.searchFieldElement.value,
			settings: window.BlogSettings || {}
		};
	}

	bindSelectors() {
		super.bindSelectors();
		Object.keys(this.selectors).forEach(key => {
			this[`${key}Element`] = document.querySelector(this.selectors[key]);
		});

		this.bindPaginatorEvents();
	}

	preventDefault(event) {
		event.preventDefault();
	}

	bindFormEvents() {
		if( this.searchFieldElement ) {
			this.searchFieldElement.addEventListener('input', this.validateSearch.bind(this));
			this.searchFieldElement.addEventListener('keypress', this.submit.bind(this));
		}
		this.btnSearchElement && this.btnSearchElement.addEventListener('click', this.search.bind(this));
		this.btnResetElement && this.btnResetElement.addEventListener('click', this.reset.bind(this));
		this.btnMoreElement && this.btnMoreElement.addEventListener('click', this.loadNextPage.bind(this));
		this.searchFormElement && this.searchFormElement.addEventListener('submit', this.preventDefault);
	}

	bindPaginatorEvents() {
		if(this.paginatorElement) {
			this.paginatorElement.querySelectorAll('[data-page]').forEach(element => {
				element.addEventListener('click', this.loadPageLinkHandler.bind(this));
			});
		}
	}

	visitPost( blog_id, blog_post_id ) {
		const url = `${Urls.Api.post(blog_id, blog_post_id)}/visit`;

		fetcher(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		});
	}

	getUrlParameter(param) {
		const name = param.replace( /[[]/, '\\[' ).replace( /[\]]/, '\\]' );
		const regex = new RegExp( '[\\?&#]' + name + '=([^&#]*)' );
		const results = regex.exec( window.location.hash );

		return results === null ? null : decodeURIComponent( results[1].replace( /\+/g, ' ' ) );
	}

	checkLocation() {
		const page = this.getUrlParameter('page');
		const search = this.getUrlParameter('search');

		if (page || search) {
			this.showResults();
		}

		if (page) {
			this.page = parseInt(page);
		}

		if (search) {
			this.searchFieldElement.value = search;

			this.validateSearch();

			this.btnSearchElement.dispatchEvent(new Event('click'));
		}

		if (!search && this.page > 1) {
			this.loadPage();
		}
	}

	checkCount() {
		if( this.postsElement && this.postsElement.querySelectorAll(this.selectors.post).length >= this.posts_per_page ) {
			if (this.btnMoreElement) {
				this.btnMoreElement.classList.remove('d-none');
			}
		}
	}

	setLocation() {
		window.location.hash = this.getRoute();
	}

	redirect() {
		window.location.href = this.blog_url + this.getRoute();
	}

	getRoute() {
		const route = `#page=${this.page}`;

		return !!this.searchFieldElement && !!this.searchFieldElement.value ?
			`${route}&search=${this.searchFieldElement.value}` :
			route;
	}

	showResults() {
		this.postsElement.classList.add('d-none');
		this.el.classList.remove('d-none');
	}

	hideResults() {
		this.postsElement.classList.remove('d-none');
		this.el.classList.add('d-none');
	}

	validateSearch() {
		if (!!this.searchFieldElement.value) {
			this.btnSearchElement.removeAttribute('disabled');
		} else {
			this.btnSearchElement.setAttribute('disabled', 'disabled');
		}
	}

	initScrollLoad() {
		this.btnMoreElement.classList.add('d-none');

		window.addEventListener('scroll', () => {
			if( !this.all_loaded ) {
				const el_bottom = window.innerWidth - this.el.getBoundingClientRect().top + this.el.offsetHeight;
				const scroll_bottom = window.scrollY + window.innerHeight;

				if( scroll_bottom >= el_bottom && !this.el.classList.contains('loading')) {
					this.loadNextPage();
				}
			}
		});
	}

	loadNextPage() {
		this.page += 1;
		this.loadPage();
	}

	loadPageLinkHandler(event) {
		if( event.target ) {
			this.loadPageByIndex(event.target.dataset.page);
		}
	}

	loadPageByIndex(index) {
		this.page = index;
		this.loadPage();
	}

	loadPage() {

		this.scrollTop();

		this.btnMoreElement && this.btnMoreElement.classList.add('progress');
		this.btnSearchElement.setAttribute('disabled', 'disabled');
		this.btnSearchElement.classList.add('progress');

		this.getPosts()
			.then(() => {
				this.btnMoreElement && this.btnMoreElement.classList.remove('progress');
				this.btnSearchElement.classList.remove('progress');

				this.validateSearch();
				this.updateList();
			});
	}

	submit(event) {
		if (event.code === 'Enter') {
			event.preventDefault();
		}
		if (event.code === 'Escape') {
			this.reset(event);
		}
	}

	search(event) {
		event.preventDefault();

		this.btnSearchElement.classList.add('progress');
		this.btnMoreElement && this.btnMoreElement.setAttribute('disabled', 'disabled');

		if (this.btnSearchElement.dataset.list) {
			this.getPosts()
				.then(() => {
					this.showResults();

					this.btnSearchElement.classList.remove('progress');
					this.btnMoreElement && this.btnMoreElement.removeAttribute('disabled');

					this.render();

					this.btnSearchElement.classList.remove('loading');
					this.el.classList.remove('loading');
					this.page = 1;
				});
		} else {
			this.redirect();
		}
	}

	reset(event) {
		event.preventDefault();

		this.btnResetElement.classList.add('d-none');
		this.searchFieldElement.value = '';
		this.hideResults();

		window.location.hash = '';
		/*
		this.validateSearch();
		this.page = 1;

		this.search(event);
		*/
	}

	getPostsData() {
		const data = {
			query: this.searchFieldElement.value,
			page: this.page
		};

		if( !data.query ) {
			if( this.el.dataset.tag && this.el.dataset.tag.length ) {
				data.tag = this.el.dataset.tag;
			}

			if( this.el.dataset.year && this.el.dataset.year.length && this.$el.dataset.month && this.$el.dataset.month.length ) {
				data.year_month = {
					Year: this.el.dataset.year,
					Month: this.el.dataset.month
				};
			}
		}

		if( this.settings.BlogCategoryName ) {
			data.category = this.settings.BlogCategoryName;
		}

		if( this.settings.BlogTag ) {
			data.tag = this.settings.BlogTag;
		}

		if( this.settings.BlogArchive ) {
			data.year_month = this.settings.BlogArchive;
		}

		return JSON.stringify(data);
	}

	getPosts() {
		const url = Urls.Api.posts(this.blog_id);

		this.el.classList.add('loading');
		this.posts = null;

		return fetcher(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: this.getPostsData()
		}).then(response => {
			this.paginator = response.Paginator;
			this.posts = response.BlogPosts;

			this.setLocation();

			if ( this.posts.length < this.posts_per_page ) {
				this.all_loaded = true;
				this.btnMoreElement && this.btnMoreElement.classList.add('d-none');
			}

			if (!!this.searchFieldElement && !!this.searchFieldElement.value && this.btnResetElement) {
				this.btnResetElement.classList.remove('d-none');
			}

			this.el.classList.remove('loading');
		});
	}

	scrollTop() {
		window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
	}

	updateList() {
		this
			.load_template()
			.then(template => {
				this.el.innerHTML = this.render_partial(template, this.get_context());
				this.after_render();
				this.el.dispatchEvent(new Event('rendered'));
				this.scrollTop();
			});
	}
}
