import BaseView from '../../../../scripts/utils/base-view.js';
import { tns } from './../../../../node_modules/tiny-slider/src/tiny-slider.js';

export default class BlogSlider extends BaseView {
	constructor({el}) {
		super(el);

		this.slider = null;
		this.selectors = {
			video: '.blog-videos .blog-videos-slides',
			videoSlides: '.blog-videos .blog-videos-slides .blog-videos-slide',
			videoLinks: '.blog-videos .blog-videos-slides .blog-videos-slide a',
			sliderControls: '.blog-videos .blog-videos-controls'
		};

		if (document.querySelector(this.selectors.video)) {
			this.bindSelectorsAll();
			this.init();
		}
	}

	bindSelectorsAll() {
		Object.keys(this.selectors).forEach(key => {
			this[`${key}Element`] = document.querySelectorAll(this.selectors[key]) ;
		});
	}

	init() {
		this.parseLinks();
		this.setSlider();
	}

	parseLinks() {
		this.videoLinksElement.forEach(linkElement => {
			const url = linkElement.getAttribute('href');
			const youtube_id = this.getYoutubeId(url);

			if (youtube_id) {
				this.setYoutubePreview(linkElement, youtube_id);
			} else {
				this.embedVideo(linkElement);
			}
		});
	}

	getYoutubeId(url) {
		const regex = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\/?\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/g;

		return (url.match(regex)) ? RegExp.$1 : false;
	}

	setYoutubePreview(linkElement, youtube_id) {
		const previewImageElement = linkElement.querySelector('img');

		previewImageElement.setAttribute('data-src', `//img.youtube.com/vi/${youtube_id}/mqdefault.jpg`);

		linkElement.classList.remove('loading');
	}

	embedVideo(linkElement) {
		const url = linkElement.getAttribute('data-embed');

		linkElement.replaceWith(`<iframe class="embed-responsive-item" src="${url}" allowfullscreen></iframe>`);
	}

	setSlider() {
		this.slider = tns({
			container: this.selectors.video,
			items: 1,
			arrowKeys: true,
			autoHeight: true,
			lazyload: true,
			lazyloadSelector: '[data-src]',
			controlsPosition: 'bottom',
			navPosition: 'bottom',
			gutter: 10,
			center: true,
			responsive: {
				'320': {
					items: 1
				},
				'565': {
					items: 2
				},
				'767': {
					items: 3
				},
				'991': {
					items: 4
				}

			},
		});
	}
}
