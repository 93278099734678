import BaseView from '../../../../scripts/utils/base-view.js';
import { tns } from './../../../../node_modules/tiny-slider/src/tiny-slider.js';

export default class BlogSlider extends BaseView {
	constructor({ el }) {
		super(el);

		this.slider = null;
		this.selectors = {
			slides: '.blog-photos-slides',
			slide: '.blog-photos-slides .blog-photos-slide'
		};
		this.settings = window.BlogSettings ? window.BlogSettings : {};

		if (document.querySelector(this.selectors.slides)) {
			this.bindSelectors();
			this.initSlider();
		}
	}

	initSlider() {
		this.slider = tns({
			container: this.selectors.slides,
			items: this.slidesElement.dataset.slidesToShow || 1,
			arrowKeys: this.slidesElement.dataset.arrows || false,
			responsive: this.settings.BlogPhotosResponsive ? this.settings.BlogPhotosResponsive : false,
			autoHeight: true,
			controlsPosition: 'bottom',
			navPosition: 'bottom',
			gutter: 10
		});
	}
}
